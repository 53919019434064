/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";


// core components

function SectionDark() {
    const websiteData = useRecoilValue(websiteDataState)
    const [selectedPhoto,setSelectedPhoto] = useState(null)

    return (
        <>
            <div className="section">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">Kijk Binnen</h2>
                            <Row style={{ paddingTop: "30px" }}>
                                {selectedPhoto && <Col lg="12"  onClick={() => setSelectedPhoto(null)}>
                                    <img className="img-thumbnail img-responsive" src={selectedPhoto?.photo?.url || ""} alt={selectedPhoto?.name} />
                                    {selectedPhoto?.name}
                                </Col>}
                                {!selectedPhoto && websiteData?.photos?.map(photo =>
                                    <Col sm="6" md="6" lg="4" onClick={() => setSelectedPhoto(photo)}>
                                        <img className="img-thumbnail img-responsive" src={photo?.photo?.url || ""} alt={photo.name} />
                                        {photo?.name}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionDark;
