/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";

import ReactMarkdown from "react-markdown"

function SectionWieZijnWe() {
    const websiteData = useRecoilValue(websiteDataState)

    return (
        <>
            <div className="section">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">{websiteData?.snippetsDictionary?.['section-header-wiezijnwe']?.text }</h2>
                            <ReactMarkdown>
                                {websiteData?.snippetsDictionary?.['section-body-wiezijnwe']?.markup.markdown }
                            </ReactMarkdown>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionWieZijnWe;
