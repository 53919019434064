/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {useState, useEffect} from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";

import ReactMarkdown from "react-markdown"

// core components
import banner from "../../assets/img/frontpage.jpg"

function IndexHeader() {

  const [isMobile, setIsMobile] = useState(false)

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  const websiteData = useRecoilValue(websiteDataState)

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
})

  return (
    <>
      <Container>
        <Row style={{ height: "365px", margin: "100px auto 0px", textAlign: isMobile ? "center" : "left"}}>
          <Col md="12" lg="7" style={{ verticalAlign: "middle" }}>
            <h1 style={{ fontSize: "30px", fontWeight: "900" }}>NOTEBAERT <span style={{ color: "#69be50", margin: "0px 10px" }}>|</span> STANDAERT<br /><span style={{ backgroundColor: "#69be50", color: "#FFFFFF", padding: "0px", marginTop: "100px", fontWeight: "500" }}>GROEPSPRAKTIJK KINESITHERAPIE</span></h1>
            <h3>{websiteData?.snippetsDictionary?.['subtitle-line1']?.text || ""}<br />{websiteData?.snippetsDictionary?.['subtitle-line2']?.text || ""}</h3>
            <h4>{websiteData?.snippetsDictionary?.['global-address']?.text || ""}<br />
              <span style={{ backgroundColor: "#00adee", color: "#FFFFFF", padding: "0px", marginTop: "100px", fontWeight: "500" }}>{websiteData?.snippetsDictionary?.['global-phoneNumber']?.text || ""}</span>
              {/* <br /> */}
              {/* {websiteData?.snippetsDictionary?.['global-email']?.text || ""} */}
            </h4>
            <br />
            <p>
              <ReactMarkdown>
                {websiteData?.snippetsDictionary?.['global-subparagraph']?.markup.markdown}
              </ReactMarkdown></p>
          </Col>
          <Col md="12" lg="5">
            <img src={banner} className="img-responsive fit-image img-thumbnail" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default IndexHeader;
