/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";
// const websiteData = useRecoilValue(websiteDataState)

function SectionDark() {
    const websiteData = useRecoilValue(websiteDataState)
    return (
        <>
            <div className="section section-dark">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">{websiteData?.snippetsDictionary?.['section-header-specialisaties']?.text}</h2>
                            <Row style={{ paddingTop: "30px" }}>
                                <div className="implode">
                                    {(websiteData?.snippetsDictionary?.['section-body-specialisaties']?.text || "")
                                        .split(",")
                                        .map(item =>
                                            <div className="btn2">{item.trim()}</div>
                                    )}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionDark;
