/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";
import ReactMarkdown from "react-markdown";

// core components
// import thumbMale from "../../assets/img/ryan.jpg"
// import thumbFemale from "../../assets/img/eva.jpg"

function SectionDark() {
    const websiteData = useRecoilValue(websiteDataState)

    return (
        <>
            <div className="section">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">{websiteData?.snippetsDictionary?.['section-header-team']?.text}</h2>
                            <ReactMarkdown>{websiteData?.snippetsDictionary?.['section-body-team']?.markup?.markdown}</ReactMarkdown>
                            <Col sm="12">
                            <img className="img-thumbnail img-responsive" src={websiteData?.snippetsDictionary?.['section-photo-team']?.photo?.url} alt='Kine Team' />
                            </Col>
                            <Row style={{ paddingTop: "30px" }} className="justify-content-md-center justify-content-lg-center">
                                {websiteData?.people?.map(person =>
                                    <>
                                    <Col sm="6" md="5" lg="5">
                                        {person?.picture?.url && <div><img className="img-thumbnail img-responsive" src={person?.picture?.url} alt={person.name} /></div>}
                                        <p className="font-weight-bold">{person.name}</p>
                                        <p>{person?.specialtities}</p></Col>
                                        </>
                                )}

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionDark;
