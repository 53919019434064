/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import {
  RecoilRoot
} from 'recoil';

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/scss/custom.scss";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";

// Get Content
ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <Switch>
        <Route path="/" render={(props) => <Index {...props} />} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);
