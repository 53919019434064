/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";

import { scroller } from 'react-scroll';

function IndexNavbar() {
  const websiteData = useRecoilValue(websiteDataState)

  // const [navbarColor, setNavbarColor] = React.useState("");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const scrollTo = (location) => {
    scroller.scrollTo(location, {
      smooth: true,
      offset: -70,
      duration: 500,
    })
  }

  return (
    <Navbar className={classnames("fixed-top", "")} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            title="Kinesitherapie Notebaert - Standaert"
          >
            <span style={{ color: "#000000" }} >Notebaert <span style={{ color: "#69be50" }}>|</span> Standaert</span><br />
            <span style={{ backgroundColor: "#69be50", color: "#FFFFFF", padding: "1px" }}>Groepspraktijk kinesitherapie</span>
          </NavbarBrand>
          <button aria-expanded={navbarCollapse} className={classnames("navbar-toggler navbar-toggler", { toggled: navbarCollapse, })} onClick={toggleNavbarCollapse} >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse} >
          <Nav navbar>
            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('wiezijnwe')}> {websiteData?.snippetsDictionary?.['section-header-wiezijnwe']?.text || ""} </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('specialisme')}> {websiteData?.snippetsDictionary?.['section-header-specialisaties']?.text || ""} </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('team')}>  {websiteData?.snippetsDictionary?.['section-header-team']?.text || ""}  </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('tarieven')}>  {websiteData?.snippetsDictionary?.['section-header-tarieven']?.text || ""}  </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('gallery')}>  {websiteData?.snippetsDictionary?.['section-header-gallery']?.text || ""}  </NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('contact')}>  {websiteData?.snippetsDictionary?.['section-header-contact']?.text || ""}  </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
