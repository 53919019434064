/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import { Element } from 'react-scroll'
import axios from "axios";

// core components
import CustomIndexNavbar from "components/Navbars/IndexNavbar.js";
import CustomIndexHeader from "components/Kine/CustomIndexHeader.js";
import SectionSpecialisatie from "components/Kine/SectionSpecialisatie.js";
import SectionWieZijnWe from "components/Kine/SectionWieZijnWe.js";
import SectionTeam from "components/Kine/SectionTeam.js";
import SectionGallery from "components/Kine/SectionGallery.js";
import SectionContact from "components/Kine/SectionContact.js";
import SectionTarieven from "components/Kine/SectionTarieven.js";

import DemoFooter from "components/Kine/Footer.js";

import { websiteDataState } from "helpers/recoil";

import {
  useRecoilState
} from 'recoil';



function Index() {
  const [websiteData,setWebsiteData] = useRecoilState(websiteDataState)

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  React.useEffect(() => {
    axios({
      url: "https://api-eu-central-1.graphcms.com/v2/ckv2466wa20sf01yzbcn829kk/master",
      method: 'post',
      data: {
        query: `
        query MyQuery {
          people {
            name
            picture {
              url
            }
            specialtities,
            riziv
          }
          photos {
            name
            photo {
              url
            }
          }
          snippets {
            reference
            text
            markup {
              markdown
            },
            photo {
              url
            }
          }
        }
              `
      }
    }
    ).then((response) => {
      let snippetsDictionary = {}
      response.data.data.snippets.map((value) => {
        snippetsDictionary[value.reference] = value
      })
      setWebsiteData({...response.data.data, snippetsDictionary});
    });
  }, []);

  // React.useEffect(() => {
  //   console.log(websiteData)
  // }, [websiteData]);


  return (
    <>
        <CustomIndexNavbar />
        <CustomIndexHeader />
        <div className="main">
          <Element name="wiezijnwe" className="wiezijnwe"><SectionWieZijnWe /></Element>
          <Element name="specialisme"><SectionSpecialisatie /></Element>
          <Element name="team"><SectionTeam /></Element>
          <Element name="tarieven"><SectionTarieven /></Element>
          <Element name="gallery"><SectionGallery /></Element>
          <Element name="contact"><SectionContact /></Element>
          <DemoFooter />
        </div>
    </>
  );
}

export default Index;
