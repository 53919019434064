/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

// core components
import { useRecoilValue } from "recoil";
import { websiteDataState } from "helpers/recoil";

function SectionContact() {
    const websiteData = useRecoilValue(websiteDataState)

    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 992) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: 50.9045068, lng: 3.5224238 }}
        >
            {props.isMarkerShown && <Marker position={{ lat: 50.9042602, lng: 3.5226789 }} />}
        </GoogleMap>
    ))

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <>
            <div className="section" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">Contact</h2>


                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col sm="12" md="12" lg="6" className="p-0">
                            <MyMapComponent
                                isMarkerShown
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDtNcC0cwtEVJS5NeWpe362KJAYS4ZL45Q&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `0%` }} />}
                                containerElement={<div style={{ height: `600px`, width: "100%", margin: 0 }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </Col>
                        <Col sm="12" md="12" lg="6" className="p-15" style={{ textAlign: isMobile ? "center" : "left" }} >
                            <h2>Contacteer ons</h2>
                            <h1 style={{ fontSize: "18px", fontWeight: "900" }}>NOTEBAERT <span style={{ color: "#69be50", margin: "0px 10px" }}>|</span> STANDAERT<br /><span style={{ backgroundColor: "#69be50", color: "#FFFFFF", padding: "0px", marginTop: "100px", fontWeight: "500" }}>GROEPSPRAKTIJK KINESITHERAPIE</span></h1>

                            <p>{websiteData?.snippetsDictionary?.['global-address']?.text || ""}</p>

                            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <p><span style={{ backgroundColor: "#00adee", color: "#FFFFFF", padding: "0px", marginTop: "100px", fontWeight: "500" }}>{websiteData?.snippetsDictionary?.['global-phoneNumber']?.text || ""}</span>
                                    <br />{websiteData?.snippetsDictionary?.['global-email']?.text || ""}</p>
                            </div>
                            <p>
                                <strong>Riziv Erkenningen:</strong>
                                <br />
                                {websiteData?.people?.map(p => p.riziv).join(", ")}
                            </p>
                            <p>
                                <strong>Ondernemingsnummer:</strong>
                                <br />
                                {websiteData?.snippetsDictionary?.['global-kbo']?.text || ""}
                            </p>
                            <p>
                                <strong>Bankrekening:</strong>
                                <br />
                                {websiteData?.snippetsDictionary?.['global-bank']?.text || ""}
                            </p>

                            <hr />

                            <p>
                                <strong>Parkeergelegenheid:</strong><br />{websiteData?.snippetsDictionary?.['section-body-contact']?.text || ""}</p>


                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionContact;
